import axios from 'axios';

import { getAuthToken, setAuthToken, removeAuthToken, removeAuthUser } from './storage';

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
})

// Attach authorisation header to requests
client.interceptors.request.use(
    (config) => {
        // Authorization header
        let authToken = getAuthToken();

        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (response) => {
        // update auth token if new one provided
        const newAuthToken = response.headers['authorization'];

        if (newAuthToken) {
            const token = newAuthToken.split(' ')[1];
            setAuthToken(token);
            console.log('refreshed token');
        }

        return response;
    },
    (error) => {
        // Log out if 401
        if (error.response.status === 401) {

            // remove auth token and user from storage
            removeAuthToken();
            removeAuthUser();

            // redirect to login page
            if (window.location.pathname.includes('/portal')) {
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default client;

/**
 * Get schools
 */
export const getSchools = async () => {
    try {
        const response = await client.get('/school');

        if (response.data.schools) {
            return response.data.schools;
        }
        
        throw new Error('No schools found');
    } catch (error) {
        throw error;
    }
}

/**
 * Get school
 */
export const getSchool = async (schoolId) => {
    try {
        const response = await client.get(`/school/${schoolId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Create school
 */
export const createSchool = async ({name, address, website, contacts, reporting_periods }) => {
    try {
        const response = await client.post('/school', {
            name: name,
            address: address,
            website: website,
            contacts: contacts, 
            periods: reporting_periods.map(period => ({
                name: period.name,
                start_date_month: period.periodStartDate.getMonth() + 1,
                start_date_day: period.periodStartDate.getDate(),
                end_date_month: period.periodEndDate.getMonth() + 1,
                end_date_day: period.periodEndDate.getDate()
            })) 
        });
        return response.data.school;
    } catch (error) {
        throw error;
    }
}

/**
 * Update school
 */
export const updateSchool = async ({schoolId, name, address, website, contacts, reporting_periods }) => {
    try {
        const response = await client.post(`/school/${schoolId}`, {
            name: name,
            address: address,
            website: website,
            contacts: contacts,
            periods: reporting_periods.map(period => ({
                name: period.name,
                start_date_month: period.periodStartDate.getMonth() + 1,
                start_date_day: period.periodStartDate.getDate(),
                end_date_month: period.periodEndDate.getMonth() + 1,
                end_date_day: period.periodEndDate.getDate()
            })) 
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}



/**
 * Get students
 * 
 * @param {string} schoolId
 * @param {string} yearGroup
 * @param {string|array} tutorGroupId
 * @param {boolean} includePeriods
 * @param {boolean} includeSchool
 * @param {string} includeTutorGroup
 */
export const getStudents = async ({schoolId, yearGroup, tutorGroupId, includePeriods, includeSchool, includeTutorGroup}) => {
    try {
        let url = new URL(process.env.REACT_APP_API_URL + 'pupil');

        // filters
        if (schoolId) {
            url.searchParams.append('school_id', schoolId);
        }
        if (yearGroup) {
            url.searchParams.append('year_group', yearGroup);
        }
        if (tutorGroupId) {
            if (!Array.isArray(tutorGroupId)) {
                tutorGroupId = [tutorGroupId];
            }
            url.searchParams.append('tutor_group_id', JSON.stringify(tutorGroupId));
        }

        // response includes
        if (includePeriods) {
            url.searchParams.append('include_pupil_periods', 'true');
        }
        if (includeSchool) {
            url.searchParams.append('include_school', 'true');
        }
        if (includeTutorGroup) {
            url.searchParams.append('include_tutor_group', 'true');
        }


        const response = await client.get(url);

        if (response.data.pupil) {
            return response.data.pupil;
        }

        throw new Error('No students found');
    } catch (error) {
        throw error;
    }
}

/**
 * Get student
 * 
 * @param {string} studentId
 */
export const getStudent = async (studentId) => {
    try {
        const response = await client.get(`/pupil/${studentId}`);
        
        if (response.data.pupil) {
            let pupil = parseNestedJsonStrings(response.data.pupil);
            return pupil;
        }

        throw new Error('No student found');
    } catch (error) {
        throw error;
    }
}

/**
 * Delete student
 * 
 * @param {string} studentId
 */
export const deleteStudent = async (studentId) => {
    try {
        const response = await client.delete(`/pupil/${studentId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


/**
 * Update pupil period
 * 
 * @param {string} studentId
 * @param {int} tutorGroupId
 * @param {int} yearGroup
 * @param {string} interventionType
 * @param {string} pupilStatus
 * @param {string} attendance
 * @param {object} bundleProgress
 * @returns {object} responseData
 */
export const updatePupilPeriod = async ({studentId, tutorGroupId, yearGroup, interventionType, pupilStatus, attendance, bundleProgress, partialPeriodCompletion}) => {
    try {
        const response = await client.post(`/pupil/${studentId}`, {
            tutor_group_id: tutorGroupId,
            year_group: yearGroup,
            intervention_type: interventionType,
            pupil_status: pupilStatus,
            attendance: attendance ? attendance : '',
            bundle_progress: bundleProgress ? JSON.stringify(bundleProgress) : '',
            //partial_period_completion: partialPeriodCompletion
        });
        
        if (response.data.pupil) {
            let pupil = parseNestedJsonStrings(response.data.pupil);
            return pupil;
        }

        throw new Error('Failed to update pupil period');
    } catch (error) {
        throw error;
    }
}

/**
 * Parse nested json strings for pupil object
 * 
 * @param {object} pupil
 * @returns {object} pupil
 */
const parseNestedJsonStrings = (pupil) => {

    pupil.pupil_periods.forEach((period) => {
        if (period.bundle_progress) {
            period.bundle_progress = JSON.parse(period.bundle_progress.replace(/\\/g, ''));
        }
        if (period.assessment) {
            period.assessment = JSON.parse(period.assessment.replace(/\\/g, ''));
        }
    });

    if (pupil.baseline_assessment) {
        pupil.baseline_assessment = JSON.parse(pupil.baseline_assessment.replace(/\\/g, ''));
    }

    return pupil;
}

/**
 * Update student
 * 
 * @param {int} student
 * @param {string} name
 * @param {int} school
 * @param {object} baseline_assessment
 * @param {string} baseline_assessment_completed_date
 */
export const updatePupilDetails = async ({studentId, school, name, baseline_assessment, baseline_assessment_completed_date, intervention_completed_date}) => {
    try {
        const response = await client.post(`/pupil/${studentId}/details`, {
            name: name,
            school_id: school,
            baseline_assessment: baseline_assessment,
            baseline_assessment_completed_date: baseline_assessment_completed_date,
            intervention_completed_date: intervention_completed_date
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Create student
 * 
 * @param {string} name
 * @param {int} school
 * @param {object} baseline_assessment
 * @param {string} baseline_assessment_completed_date
 * @param {int} tutor_group
 * @param {int} year_group
 * @param {string} interventionType
 * @param {string} pupil_status
 * @param {string} attendance
 */
export const createStudent = async ({school, name, baseline_assessment, baseline_assessment_completed_date, tutor_group, year_group, intervention_type, pupil_status, attendance, intervention_completed_date, partial_period_completion}) => {
    try {
        const response = await client.post(`/pupil`, {
            name: name,
            school_id: school,
            baseline_assessment: baseline_assessment,
            baseline_assessment_completed_date: baseline_assessment_completed_date,
            intervention_completed_date: intervention_completed_date,
            pupil_period: {
                tutor_group_id: tutor_group,
                year_group: year_group,
                intervention_type: intervention_type,
                pupil_status: pupil_status,
                attendance: attendance,
                //partial_period_completion: partial_period_completion
            }
        });
        return response.data.pupil; 
    } catch (error) {
        throw error;
    }
};

/**
 * Get data resource
 */
export const getDataResource = async (resourceSlug) => {
    try {
        const response = await client.get(`/data/${resourceSlug}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Get tutors
 * 
 * @param {string|null} schoolId
 */
export const getTutors = async ({schoolId = null}) => {
    try {
        let url;

        if (schoolId) {
            url = `/tutor?school_id=${schoolId}`;
        } else {
            url = `/tutor`;
        }

        const response = await client.get(url);

        return response.data.tutors;
    } catch (error) {
        throw error;
    }
}

/**
 * Get tutor
 * 
 * @param {string} tutorId
 */
export const getTutor = async ({tutorId}) => {
    try {
        const response = await client.get(`/tutor/${tutorId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Create tutor
 * 
 * @param {string} school
 * @param {string} name
 * @param {string} email
 */
export const createTutor = async ({school, name, email}) => {
    try {
        const response = await client.post(`/tutor`, {
            school_id: school,
            name: name,
            user: {
                email: email
            }
        });
        return response.data.tutor;
    } catch (error) {
        throw error;
    }
}


/**
 * Update tutor
 * 
 * @param {string} id
 * @param {string} school
 * @param {string} name
 * @param {string} email
 */
export const updateTutor = async ({id, school, name, email}) => {
    try {
        const response = await client.post(`/tutor/${id}`, {
            school_id: school,
            name: name,
            user: {
                email: email
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


/**
 * Get tutor groups
 * 
 * @param {string} schoolId
 * @param {string} tutorId
 */
export const getTutorGroups = async ({schoolId, tutorId}) => {
    try {
        let url = new URL(process.env.REACT_APP_API_URL + 'tutor-group');

        // filters
        if (schoolId) {
            url.searchParams.append('school_id', schoolId);
        }
        if (tutorId) {
            url.searchParams.append('tutor_id', tutorId);
        }

        const response = await client.get(url);

        return response.data.tutor_groups;
    } catch (error) {
        throw error;
    }
}


/**
 * Get tutor group
 * 
 * @param {string} id
 */
export const getTutorGroup = async ({id}) => {
    try {
        const response = await client.get(`/tutor-group/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Create tutor group
 * 
 * @param {int} school
 * @param {int} tutor
 * @param {string} name
 */
export const createTutorGroup = async ({school, tutor, name}) => {
    try {
        const response = await client.post(`/tutor-group`, {
            school_id: school,
            tutor_id: tutor,
            name: name
        });
        return response.data.tutor_groups;
    } catch (error) {
        throw error;
    }
}

/**
 * Update tutor group details
 * 
 * @param {int} id
 * @param {int} school
 * @param {int} tutor
 * @param {string} name
 */
export const updateTutorGroup = async ({id, school, tutor, name}) => {
    try {
        const response = await client.post(`/tutor-group/${id}`, {
            school_id: school,
            tutor_id: tutor,
            name: name
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Archive tutor
 * 
 * @param {int} tutorId
 */
export const archiveTutor = async (tutorId) => {
    try {
        const response = await client.post(`/tutor/${tutorId}`, {
            'archived': true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Archive tutor group
 * 
 * @param {int} tutorGroupId
 */
export const archiveTutorGroup = async (tutorGroupId) => {
    try {
        const response = await client.post(`/tutor-group/${tutorGroupId}`, {
            'archived': true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Add notes to student
 *
 * @param {int} studentId
 * @param {object} noteData
 */
export const addStudentNote = async ({ studentId, notes }) => {
    try {
        const response = await client.post(`/pupil/${studentId}/notes`, notes);
        return response.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Updates notes for student
 * 
 * @param {int} studentId
 * @param {object} noteData
 */
export const updateStudentNote = async ({ studentId, noteId, content, nextSteps, followUp }) => {
    try {
        const response = await client.post(`/pupil/${studentId}/notes/${noteId}`, {
            note_id: noteId,
            content: content,
            next_steps: nextSteps,
            follow_up: followUp
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Delete single student note
 * 
 * @param {int} studentId
 * @param {int} noteId
 */
export const deleteStudentNote = async ({ studentId, noteId }) => {
    try {
        const response = await client.delete(`/pupil/${studentId}/notes/${noteId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Get users
 */
export const getUsers = async () => {
    try {
        let url = new URL(process.env.REACT_APP_API_URL + 'user');

        const response = await client.get(url);

        return response.data.users;
    } catch (error) {
        throw error;
    }
}

/**
 * Get user
 * 
 * @param {string} userId
 */
export const getUser = async ({userId}) => {
    try {
        const response = await client.get(`/user/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Update user
 * 
 * @param {int} id
 * @param {string} email
 * @param {string} name
 * @param {string} userRole
 * @param {int} school
 */
export const updateUser = async ({id, email, name, userRole, school}) => {
    try {
        const response = await client.post(`/user/${id}`, {
            email: email,
            name: name,
            user_role: userRole,
            school_id: school
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Create user
 * 
 * @param {string} email
 * @param {string} name
 * @param {string} userRole
 * @param {int} school
 */
export const createUser = async ({email, name, userRole, school}) => {
    try {
        const response = await client.post(`/user`, {
            email: email,
            name: name,
            user_role: userRole,
            school_id: school
        });
        return response.data.user;
    } catch (error) {
        throw error;
    }
}

/**
 * Delete user
 * 
 * @param {int} userId
 */
export const deleteUser = async (userId) => {
    try {
        const response = await client.delete(`/user/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

/**
 * Send password reset
 * 
 * @param {int} userId
 * @param {string} email
 */
export const sendPasswordReset = async ({userId, email}) => {

    if (!userId && !email) {
        throw new Error('No user id or email provided');
    }

    if (userId) {
        try {
            const response = await client.get(`/user/${userId}/pswd-reset-request`);
            return response.data;
        } catch (error) {
            throw error;
        }
    } else if (email) {
        try {
            const response = await client.post(`/user/pswd-reset-request`, {
                email: email
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

/**
 * Reset password
 * 
 * @param {string} email
 * @param {string} token
 * @param {string} password
 */
export const resetPassword = async ({email, token, password}) => {
    try {
        const response = await client.post(`/user/pswd-reset`, {
            email: email,
            token: token,
            password: password
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}