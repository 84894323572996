import React, {useState, useEffect} from "react";
import CustomSelect from "../customSelect/CustomSelect";
import { getSchools, getStudents } from "../../services/scpApi";
import { yearGroups } from "../../data/data";


const SelectStudent = ({selectedStudent, setSelectedStudent}) => {

    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState({});
    const [selectedYearGroup, setSelectedYearGroup] = useState({});
    const [students, setStudents] = useState([]);

    // get schools
    useEffect(() => {
        (async () => {
            try {
                const response = await getSchools();
                setSchools(response);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])

    // get students
    useEffect(() => {
        setSelectedStudent({});

        if(selectedSchool.id && selectedYearGroup.year) {
            (async () => {
                try {
                    let students = await getStudents({
                        'schoolId': selectedSchool.id,
                        'yearGroup': selectedYearGroup.year
                    });

                    // order students by name alphabetically
                    students = students.sort((a, b) => a.name.localeCompare(b.name));

                    setStudents(students);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [selectedSchool, selectedYearGroup])

    return (
        <div className="flex-row-select-cont">

            <CustomSelect
                name="select-school" 
                placeholder="Select School"
                options={schools}
                labelKey="name"
                setSelectedOption={(option) => setSelectedSchool(option)}
                selectedOption={selectedSchool}
            />

            <CustomSelect
                name="select-year-group" 
                placeholder="Select Year Group"
                options={yearGroups}
                labelKey="name"
                setSelectedOption={(option) => setSelectedYearGroup(option)}
                selectedOption={selectedYearGroup}
            />

            <CustomSelect
                name="select-student" 
                placeholder="Select Student"
                options={students}
                labelKey="name"
                setSelectedOption={(option) => setSelectedStudent(option)}
                selectedOption={selectedStudent}
            />
        </div>
    );
}

export default SelectStudent;