import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addStudentNote, updateStudentNote, deleteStudentNote } from '../../services/scpApi';

function Notes({ notes, pupilId, onAddNote }) {
    const user = useSelector((state) => state.auth.authUser);

    const [loading, setLoading] = useState(null);
    const [editingNoteId, setEditingNoteId] = useState(null);

    const [formData, setFormData] = useState({
        content: '',
        nextSteps: '',
        followUp: '',
    });

    const [editFormData, setEditFormData] = useState({
        followUp: ''
    });

    const [validationErrors, setValidationErrors] = useState([]);

    // Clear the form data when pupilId changes
    useEffect(() => {
        setFormData({
            content: '',
            nextSteps: '',
            followUp: '',
        });
        setEditFormData({
            followUp: ''
        });
        setEditingNoteId(null);
    }, [pupilId])

    // Handle input change for notes and next steps
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    // Start editing a note
    const handleEditNote = (note) => {
        setEditingNoteId(note.id);
        setEditFormData({
            content: note.note,
            nextSteps: note.next_steps,
            followUp: note.follow_up,
        });
    }

    /**
     * 
     * @param {number} noteId 
     */
    const handleSaveNote = async (noteId) => {
        try {
            const response = await updateStudentNote({
                studentId: pupilId,
                noteId: noteId, 
                content: editFormData.content,
                nextSteps: editFormData.nextSteps,
                followUp: editFormData.followUp 
            });

            // Update notes on frontend
            onAddNote(response.pupil.notes);
            setEditingNoteId(null); // Exit edit mode
        } catch (error) {
            setValidationErrors(['Failed to update the note.']);
        }
    }
    

    /**
     * Handle delete note
     * 
     * @param {number} noteId - The id of the note to delete
     */
    const handleDeleteNote = async (noteId) => {

        // alert to confirm deletion
        const confirmDelete = window.confirm('Are you sure you want to delete this note?');

        if (!confirmDelete) {
            return;
        }

        try {
            // Delete the note from the backend
            const response = await deleteStudentNote({
                studentId: pupilId,
                noteId: noteId
            });

            console.log(response);

            onAddNote(response.pupil.notes);
        } catch (error) {
            console.log('Error deleting note:', error);
            setValidationErrors(['Failed to delete the note.']);
        }
    }


    /**
     * Add a new note to the student
     */
    const handleAddNote = async (e) => {
        e.preventDefault();
        setValidationErrors([]);

        // Validation to check if content or nextSteps are empty
        if (!formData.content.trim() || !formData.nextSteps.trim()) {
            setValidationErrors(['Both notes and next steps fields are required.']);
            return; 
        }

        const newNote = {
            note: formData.content,
            next_steps: formData.nextSteps,
            follow_up: formData.followUp,
        };

        try {
            setLoading('loading');
            if (pupilId) {
                const response = await addStudentNote({ studentId: pupilId, notes: newNote });
                onAddNote(response.pupil.notes);
                setLoading('success');
                setTimeout(() => {
                  setLoading(null);
                }, 3000);
            } else {
                console.error('No pupil id provided');
                setLoading('error');
                setTimeout(() => {
                    setLoading(null);
                }, 3000);
            }

            // Clear input fields
            setFormData({
                content: '',
                nextSteps: '',
                followUp: '',
            });
        } catch (error) {
            // Handle server side validation errors
            const errors = [];

            if (error.response && error.response.data && error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    errors.push(...error.response.data.errors[key]);
                });
            }

            setValidationErrors(errors);
            console.error('Error adding note:', error);
            setLoading(null);
        }
    }

    const formatDate = (dateString) => {
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    return (
        
        <div className="notes">

            <div className="separator"></div>
            
            <h3>Notes</h3>
            <div className="notes-outer-cont">
                <div className="headings">
                    <div className="notes-table">
                        <div className="name">Author</div>
                        <div className="date">Date</div>
                        <div className="note-content">Notes</div>
                    </div>
                </div>

                <div className="separator"></div>

                <div className='note-table-cont'>
                    {(notes && notes.length > 0) ? (
                        notes.map((note, index) => (
                            <div key={index} className="notes-table">
                                <div className="name"><p>{note.created_by}</p></div>
                                <div className="date"><p>{formatDate(note.created_at)}</p></div>
                                {editingNoteId === note.id ? (
                                    <table className="notes-cont">
                                        <tbody>
                                            <tr>
                                                <td>Notes:</td>
                                                <textarea
                                                        type="text"
                                                        name="content"
                                                        value={editFormData.content}
                                                        onChange={handleEditInputChange}
                                                    />
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Next Steps:</td>
                                                <textarea
                                                        type="text"
                                                        name="nextSteps"
                                                        value={editFormData.nextSteps}
                                                        onChange={handleEditInputChange}
                                                    />
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Follow Up Notes:</td>
                                                <td>
                                                    <textarea
                                                        type="text"
                                                        name="followUp"
                                                        value={editFormData.followUp}
                                                        onChange={handleEditInputChange}
                                                    />
                                                </td>
                                                <td>
                                                    <button className="update-notes-button text-button" onClick={() => handleSaveNote(note.id)}>
                                                        Save
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <table className="notes-cont">
                                        <tbody>
                                            <tr>
                                                <td>Notes:</td>
                                                <td>{note.note}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Next Steps:</td>
                                                <td>{note.next_steps}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Follow Up Notes:</td>
                                                <td>{note.follow_up}</td>
                                                <td>
                                                    <button className="update-notes-button text-button" onClick={() => handleDeleteNote(note.id)}>
                                                    Delete
                                                    </button>
                                                    <button className="update-notes-button text-button" onClick={() => handleEditNote(note)}>
                                                    Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="notes-table">
                            <p>No notes available</p>
                        </div>
                    )}
                </div>


                <div className="add-note-cont">
                    {user.name && (
                        <div className="username">
                        <span>{user.name}:</span>
                        </div>
                    )}
                    <form onSubmit={handleAddNote}>
                        <div className="note-input">
                            <label htmlFor="note-input">Notes</label>
                            <textarea
                                id="note-input"
                                name="content"
                                value={formData.content}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="next-steps-input">
                            <label htmlFor="next-steps-input">Next Steps</label>
                            <textarea
                                id="next-steps-input"
                                name="nextSteps"
                                value={formData.nextSteps}
                                onChange={handleInputChange}
                            />
                        </div>
                        <button 
                            className={` add-notes-btn button ${loading === 'loading' ? 'button-loading' : ''}`} 
                            type="submit"
                            disabled={loading === 'loading'}
                        >
                            {loading === 'loading' ? 'Adding note...' : 'Add'}
                        </button>
                    </form>

                    {/* Display Validation Errors */}
                    {validationErrors.length > 0 && (
                        <div className="error-messages">
                        {validationErrors.map((error, index) => (
                            <p key={index} className="error-text">
                            {error}
                            </p>
                        ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Notes;
