// EditStudentModal.js
import React, { useState, useEffect } from 'react';
import { createTutorGroup, updateTutorGroup, getTutors, archiveTutorGroup, getSchools } from '../../services/scpApi'; // Import API methods
import CustomSelect from '../customSelect/CustomSelect'
import bin from '../../assets/bin.svg';
import SaveButton from '../saveButton/SaveButton';


const CrudTutorGroupSingle = ({tutorGroup, setTutorGroup, setView, loading, setLoading, refreshSelectedTutorGroup}) => {

    const [formData, setFormData] = useState({
        school: '',
        tutorGroup: '',
        tutor: ''
    });
    const [schools, setSchools] = useState([]);
    const [tutors, setTutors] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    // Prepare form data for editing existing tutor group
    useEffect(() => {
        if (!tutorGroup || !tutorGroup.id) {
            return;
        }

        // Set form data for updating tutor group
        const newFormData = {
            school: tutorGroup.school.id || '',
            name: tutorGroup.name || '',
            tutor: tutorGroup.tutor.id || '' 
        };

        setFormData(newFormData);
    }, [tutorGroup]);

    // Get schools for select
    useEffect(() => {
        (async () => {
            try {
                const schoolsData = await getSchools();
                setSchools(schoolsData);

                const tutorsData = await getTutors({
                    schoolId: formData.school
                });
                setTutors(tutorsData);
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
        })();
    }, []);

    // Handle form data changes
    const handleChange = (e) => {
        // Unset validation errors
        setValidationErrors([]);

        // Handle form data change
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // handle archive tutor group
    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to archive this tutor group?')) {
            try {
                setLoading('loading');

                await archiveTutorGroup(tutorGroup.id);

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                }, 3000);

                refreshSelectedTutorGroup();  
                setView('list');  

            } catch (error) {

                setLoading('error');
                console.error('Error archiving tutor group:', error);
            }
        } else {
            console.log('Archiving canceled');
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationErrors([]);
        setLoading(null);

        // Save tutor
        try {
            setLoading('loading');
            if (tutorGroup) {
                // Editing existing tutor group
                await updateTutorGroup({
                    id: tutorGroup.id,
                    school: formData.school,
                    name: formData.name,
                    tutor: formData.tutor
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    refreshSelectedTutorGroup();
                }, 3000);

            } else {
                // Creating new tutor group
                const tutorGroup = await createTutorGroup({
                    school: formData.school,
                    name: formData.name,
                    tutor: formData.tutor
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    //refreshSelectedTutorGroup();
                    setTutorGroup(tutorGroup);
                }, 3000);

            }
        } catch (error) {
            // Server-side validation
            const errors = [];

            if (error.response?.data?.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    errors.push(error.response.data.errors[key]);
                });
            } else if (error.response?.data?.message) {
                errors.push(error.response.data.message);
            }
            setValidationErrors(errors);
            console.error('Error saving tutor:', error);

            setLoading('error');
            setTimeout(() => {
                setLoading(null);
            }, 3000);
        }
    };

    const routeKey = 'manage-tutor-groups';
    const handleMenuBtnClick = () => setView({ isBackToList: true });

    // add event listener to sidebar buttons to change view
    useEffect(() => {
        const sidebarButtons = document.querySelectorAll('.sidebar a[href^="/portal/' + routeKey + '"]');
        sidebarButtons.forEach(button => {
            // if it doesn't already have an event listener
            if (!button.hasEventListener) {
                button.addEventListener('click', handleMenuBtnClick);
            }
        })

        // Cleanup to prevent memory leaks and double bindings
        return () => {
            sidebarButtons.forEach(button => {
                button.removeEventListener('click', handleMenuBtnClick);
            });
        };
    }, []);

    return (
        <div className="single-edit">
            <h2>{tutorGroup ? 'Edit Tutor Group' : 'Add New Tutor Group'}</h2>
            <img className='bin-icon' src={bin} alt="rubbish bin icon" onClick={handleDelete}/>
            <form onSubmit={handleSubmit}>
                <div className='single-inner-cont'>
                <label>School:</label>
                <CustomSelect 
                    name="select-school" 
                    placeholder="Select School"
                    options={schools}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'school', value: option.id } })}
                    selectedOption={schools.find(school => school.id === formData.school)}
                />

                <label>Tutor Group Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label>Tutor Group Lead:</label>
                <span className="field-instruction">Use manage tutor group leads tab to create new tutors</span>
                <CustomSelect 
                    name="select-tutor-group-lead" 
                    placeholder="Select Tutor Group Lead"
                    options={tutors} // Use the fetched tutor group leads
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'tutor', value: option.id } })}
                    selectedOption={tutors.find(tutor => tutor.id === formData.tutor)} // Preselect the lead if editing
                />
                </div>

                <div className="flex-row-buttons-cont">
                    <button onClick={() => { setView('list') }} className="grey-btn">Close</button>
                    <SaveButton
                        save={handleSubmit}
                        loading={loading}
                        textCreate="Add Tutor Group"
                        textCreateLoading="Saving..."
                        textCreateSuccess="Saved"
                        textCreateError="Error"
                        textUpdate="Update Tutor Group"
                        textUpdateLoading="Updating..."
                        textUpdateSuccess="Updated"
                        textUpdateError="Error"
                        isUpdating={!!tutorGroup} 
                    />
                </div>

                {validationErrors.length > 0 &&
                    <div className="error-messages">
                        {validationErrors.map((error, index) => {
                            return <p key={index}>{error}</p>
                        })}
                    </div>
                }
            </form>
        </div>
    );
};

export default CrudTutorGroupSingle;
