import React, { useState, useEffect } from "react";
import { getSchool } from '../../services/scpApi';
import CrudSchoolList from "../../features/crudSchool/CrudSchoolList";
import CrudSchoolSingle from "../../features/crudSchool/CrudSchoolSingle";
import CrudLayout from "../../layouts/CrudLayout";

const ManageSchools = () => {

    const [view, setView] = useState("")
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedSchool, setSchool] = useState(null);
    const [loading, setLoading] = useState(null);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setSchool(null);
    }

    const refreshSelectedSchool = () => {
        if (!selectedSingleItemId) {
            return;
        }
        (async () => {
            try {
                const data = await getSchool(selectedSingleItemId);
                setSchool(data.school);
            } catch (error) {
                console.error('Error fetching student:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch student data
                const data = await getSchool(selectedSingleItemId);
                setSchool(data.school);
            } catch (error) {
                console.error('Error fetching school:', error);
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <CrudLayout
            view={view}
            setView={setView}
            selectedSingleItemId={selectedSingleItemId}
            setSelectedSingleItemId={setSelectedSingleItemId}    
        >
            <div className="crud-page">
                <h1>Manage Schools</h1>

                {view === "list" ? (
                    <>
                        <button onClick={handleAddNew} className="add-new">Add New School</button>
                        <CrudSchoolList setSelectedSingleItemId={setSelectedSingleItemId} setView={() => setView('single')} />
                    </>
                ) : (
                    <CrudSchoolSingle 
                        loading={loading}
                        setLoading={setLoading}
                        school={selectedSchool}
                        setSchool={setSchool} 
                        setView={() => setView('list')} 
                        refreshSelectedSchool={refreshSelectedSchool} 
                    />
                )}
            </div>
        </CrudLayout>
    );
}

export default ManageSchools;