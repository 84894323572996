// EditStudentModal.js
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { createStudent, updatePupilPeriod, deleteStudent } from '../../services/scpApi'; // Import API methods
import CustomSelect from '../customSelect/CustomSelect';
import BaselineAssessmentForm from '../baselineAssessmentForm/BaselineAssessmentForm';
import { yearGroups, studentStatuses, typesOfIntervention, attendanceIndicators } from '../../data/data';
import { getTutors, getTutorGroups, getSchools, updatePupilDetails, getDataResource } from '../../services/scpApi';
import DatePicker from "react-datepicker";
import bin from '../../assets/bin.svg';
import CustomCheckbox from '../customCheckbox/CustomCheckbox';
import SaveButton from '../saveButton/SaveButton';


const CrudStudentSingle = ({student, setStudent, setView, loading, setLoading, refreshSelectedStudent }) => {

    const [formData, setFormData] = useState({
        school: '',
        name: '',
        yearGroup: '',
        tutor: '',
        tutorGroup: '',
        studentStatus: '',
        interventionType: '',
        attendance: '',
        baselineAssessment: '',
        baselineAssessmentCompletedDate: '',
        interventionCompletedDate: '01/01/1970',
        partialPeriodCompletion: false
    });
    const [latestPeriod, setLatestPeriod] = useState({});
    const [schools, setSchools] = useState([]);
    const [tutors, setTutors] = useState([]);
    const [tutorGroups, setTutorGroups] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [assessmentSchema, setAssessmentSchema] = useState(null);
    const user = useSelector(state => state.auth.authUser);


    // prepare form data for editing existing student
    useEffect(() => {
        if (!student) {
            return;
        }

        (async () => {
            try {
                // get assessment schema
                const blankAssessmentSchema = await getDataResource('maths-assessment');

                // set form data for updating student
                if (student) {
                    const mostRecentPeriod = student.pupil_periods[student.pupil_periods.length - 1] || {};
                    setLatestPeriod(mostRecentPeriod);
        
                    const newFormData = {
                        school: student.school.id || '',
                        name: student.name || '',
                        yearGroup: mostRecentPeriod.year_group || '',
                        tutor: mostRecentPeriod.tutor && mostRecentPeriod.tutor.id || '',
                        tutorGroup: mostRecentPeriod.tutor_group && mostRecentPeriod.tutor_group.id || '',
                        studentStatus: mostRecentPeriod.pupil_status || '',
                        interventionType: mostRecentPeriod.intervention_type || '',
                        attendance: mostRecentPeriod.attendance || '',
                        baselineAssessment: student.baseline_assessment || blankAssessmentSchema,
                        baselineAssessmentCompletedDate: student.baseline_assessment_completed_date || '',
                        interventionCompletedDate: student.intervention_completed_date || '',
                        partialPeriodCompletion: mostRecentPeriod.partial_period_completion || false
                    };
        
                    setFormData(newFormData);
                }
                
                // set back to default if no student is selected
                else {        
                    setFormData({
                        school: '',
                        name: '',
                        yearGroup: '',
                        tutor: '',
                        tutorGroup: '',
                        studentStatus: '',
                        interventionType: '',
                        attendance: '',                        
                        baselineAssessment: '',
                        baselineAssessmentCompletedDate: '',
                        interventionCompletedDate: '',
                        partialPeriodCompletion: false
                    });
                }

            } catch (error) {
                console.error('Error fetching assessment schema:', error);
            }
        })();

    }, [student]);

    // get schools for select
    useEffect(() => {
        (async () => {
            try {
                const schoolsData = await getSchools();
                setSchools(schoolsData);
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
        })();
    }, []);

    // get assessment schema
    useEffect(() => {
        (async () => {
            try {
                const assessmentSchema = await getDataResource('maths-assessment');
                console.log('assessment schema:', assessmentSchema);

                // set the form data baseline assessment = assessment schema if it doesn't already exist
                if (!formData.baselineAssessment) {
                    handleBaselineChange(assessmentSchema);
                    setAssessmentSchema(assessmentSchema);
                }
                
                // otherwise use the existing baseline assessment as the schema
                else {
                    setAssessmentSchema(student.baseline_assessment);
                }

            } catch (error) {
                console.error('Error fetching assessment schema:', error);
            }
        })();
    }, []);

    const routeKey = 'manage-students';
    const handleMenuBtnClick = () => setView({ isBackToList: true });

    // add event listener to sidebar buttons to change view
    useEffect(() => {
        const sidebarButtons = document.querySelectorAll('.sidebar a[href^="/portal/' + routeKey + '"]');
        sidebarButtons.forEach(button => {
            // if it doesn't already have an event listener
            if (!button.hasEventListener) {
                button.addEventListener('click', handleMenuBtnClick);
            }
        })

        // Cleanup to prevent memory leaks and double bindings
        return () => {
            sidebarButtons.forEach(button => {
                button.removeEventListener('click', handleMenuBtnClick);
            });
        };
    }, []);

    // get tutor groups for select
    useEffect(() => {
        if (!formData.school) {
            return;
        }

        (async () => {
            try {
                const tutorGroupsData = await getTutorGroups({
                    school: formData.school.id
                });

                // prefix tutor group name with tutor name
                tutorGroupsData.forEach(group => {
                    group.name = `${group.tutor.name} - ${group.name}`;
                });

                // sort alphabetically
                tutorGroupsData.sort((a, b) => a.name.localeCompare(b.name));

                setTutorGroups(tutorGroupsData);
            } catch (error) {
                console.error('Error fetching tutor groups:', error);
            }
        })();
    }, [formData.school]);


    // handle form data changes
    const handleChange = (e) => {
        // unset validation errors
        setValidationErrors([]);

        // handle form data change
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // handle baseline assessment change
    const handleBaselineChange = (baselineAssessment) => {
        setFormData(prevData => ({
            ...prevData,
            baselineAssessment
        }));

        console.log('baseline assessment changed:', baselineAssessment);
    };

    // handle delete student
    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this student?')) {
            try {
                setLoading('loading');

                await deleteStudent(student.id);

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                }, 3000);

                refreshSelectedStudent();  
                setView('list');  

            } catch (error) {

                setLoading('error');
                console.error('Error deleting student:', error);
            }
        } else {
            console.log('Deletion canceled');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationErrors([]);
        setLoading('loading');

        console.log('completion date:', formData.interventionCompletedDate);

        // save student
        try {
            if (student) {
                // Editing existing student
                await updatePupilDetails({
                    studentId: student.id,
                    school: formData.school,
                    name: formData.name,
                    baseline_assessment: formData.baselineAssessment,
                    baseline_assessment_completed_date: formData.baselineAssessmentCompletedDate,
                    intervention_completed_date: formData.interventionCompletedDate
                });

                await updatePupilPeriod({
                    studentId: student.id,
                    tutorGroupId: formData.tutorGroup,
                    yearGroup: formData.yearGroup,
                    interventionType: formData.interventionType,
                    pupilStatus: formData.studentStatus,
                    attendance: formData.attendance,
                    //partialPeriodCompletion: formData.partialPeriodCompletion
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    setView('list');
                    refreshSelectedStudent();
                }, 3000);


            } else {
                // Creating new student 
                const student = await createStudent({
                    school: formData.school,
                    name: formData.name,
                    baseline_assessment: formData.baselineAssessment,
                    baseline_assessment_completed_date: formData.baselineAssessmentCompletedDate,
                    intervention_completed_date: formData.interventionCompletedDate,
                    tutor_group: formData.tutorGroup,
                    year_group: formData.yearGroup,
                    intervention_type: formData.interventionType,
                    pupil_status: formData.studentStatus,
                    attendance: formData.attendance
                    //partial_period_completion: formData.partialPeriodCompletion
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    setView('list');
                }, 3000);

            }
        } catch (error) {
            // server side validation
            const errors = [];

            if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    errors.push(error.response.data.errors[key]);
                });
            } else if (error.response.data.message) {
                errors.push(error.response.data.message);
            }
            setValidationErrors(errors);
            console.error('Error saving student:', error);

            setLoading('error');
            setTimeout(() => {
                setLoading(null);
            }, 3000);
        }
    };

    return (
        <div className="single-edit">
            <h2>{student ? 'Edit Student' : 'Add New Student'}</h2>
            {user.user_role === 'super_admin' && (
                <img className='bin-icon' src={bin} alt="rubbish bin icon" onClick={handleDelete} />
            )}
            <form onSubmit={handleSubmit}>
                <div className='single-inner-cont'>
                <label>School:</label>
                <CustomSelect 
                    name="select-school" 
                    placeholder="Select School"
                    options={schools}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'school', value: option.id } })}
                    selectedOption={schools.find(group => group.id === formData.school)}
                />

                <label>Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label>Year Group:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomSelect 
                    name="select-year-group" 
                    placeholder="Select Year Group"
                    options={yearGroups}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'yearGroup', value: option.year } })}
                    selectedOption={yearGroups.find(yearGroup => yearGroup.year === formData.yearGroup)}
                />

                {/* <label>Tutor:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomSelect 
                    name="select-tutor" 
                    placeholder="Select Tutor"
                    options={tutors}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'tutor', value: option.id } })}
                    selectedOption={tutors.find(tutor => tutor.id === formData.tutor)}
                /> */}

                <label>Tutor Group:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomSelect 
                    name="select-tutor-group" 
                    placeholder="Select Tutor Group"
                    options={tutorGroups}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'tutorGroup', value: option.id } })}
                    selectedOption={tutorGroups.find(tutorGroup => tutorGroup.id === formData.tutorGroup)}
                />

                <label>Intervention Type:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomSelect 
                    name="intervention-type" 
                    placeholder="Select type"
                    options={typesOfIntervention}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'interventionType', value: option.type } })}
                    selectedOption={typesOfIntervention.find(option => option.type === formData.interventionType)}
                />

                <label>Student Status:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomSelect 
                    name="student-status" 
                    placeholder="Select status"
                    options={studentStatuses}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'studentStatus', value: option.status } })}
                    selectedOption={studentStatuses.find(status => status.status === formData.studentStatus)}
                />

                <label>Intervention Completed Date:</label>
                <span className="field-instruction">Leave empty if student not yet completed intervention</span>
                <div className="date-selector-cont">
                    <DatePicker
                        selected={formData.interventionCompletedDate ? new Date(formData.interventionCompletedDate) : new Date('01/01/1970')}
                        onChange={(date) => handleChange({ target: { name: 'interventionCompletedDate', value: date } })}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Date"
                    />
                </div>

                <label>Attendance Indicator:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomSelect 
                    name="attendance" 
                    placeholder="Select status"
                    options={attendanceIndicators}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'attendance', value: option.status } })}
                    selectedOption={attendanceIndicators.find(status => status.status === formData.attendance)}
                />

                {/* <label>Student has not completed the full period:</label>
                {latestPeriod && latestPeriod.period_name &&
                    <span className="field-instruction">Editing for period: {latestPeriod.period_name} {latestPeriod.calendar_year}</span>
                }
                <CustomCheckbox
                    name="partial-completed-period" 
                    label="Partially Completed Period" 
                    checked={formData.partialPeriodCompletion}
                    setChecked={(checked) => handleChange({ target: { name: 'partialPeriodCompletion', value: checked}})}
                /> */}

                <div className="separator"></div>

                <label>Baseline Assessment Completed Date:</label>
                <div className="date-selector-cont">
                    <DatePicker
                        selected={formData.baselineAssessmentCompletedDate ? new Date(formData.baselineAssessmentCompletedDate) : null}
                        onChange={(date) => handleChange({ target: { name: 'baselineAssessmentCompletedDate', value: date } })}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Date"
                    />
                </div>

                <label>Baseline Assessment:</label>
                <span className="field-instruction">Click the level boxes to toggle status. Hold shift and click to fill in all previous levels of a single topic.</span>
                {assessmentSchema ?
                    <BaselineAssessmentForm 
                        baselineAssessment={formData.baselineAssessment} 
                        handleBaselineChange={(baselineAssessment) => handleBaselineChange(baselineAssessment)} 
                    />
                    :
                    <div className="loading baseline-assessment-form"></div>
                }
                </div>

                <div className="flex-row-buttons-cont">
                    <button onClick={() => {setView('list')}} className="grey-btn">Close</button>
                    <SaveButton
                        save={handleSubmit}
                        loading={loading}
                        textCreate="Add Student"
                        textCreateLoading="Saving..."
                        textCreateSuccess="Saved"
                        textCreateError="Error"
                        textUpdate="Update Student"
                        textUpdateLoading="Updating..."
                        textUpdateSuccess="Updated"
                        textUpdateError="Error"
                        isUpdating={!!student} 
                    />
                </div>

                {validationErrors.length > 0 &&
                    <div className="error-messages">
                        {validationErrors.map((error, index) => {
                            return <p key={index}>{error}</p>
                        })}
                    </div>
                }
            </form>
            
        </div>
    );
};

export default CrudStudentSingle;
