import React, {useEffect} from "react";
import { useLocation } from "react-router";
import Sidebar from "../features/sidebar/Sidebar";
import User from "../features/user/User";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const PortalLayout = ({children}) => {

    const location = useLocation();

    useEffect(() => {
        console.log(location);
    }, [location]);


    return (
        <div className="portal-layout">

            <div className="header">
                <div className="content-wrap">
                    <div className="content">
                        <Link to="/portal/" className="header-logo">
                            <img src={require('../assets/SCP-logo.svg').default} alt="The School Consultancy Programme" className="header-logo" />
                        </Link>
                        <User />
                    </div>
                </div>
            </div>
        
            <div className="main">
                <div className="content-wrap">
                    <div className="content">
                        <Sidebar />
                        <div className="main-page">
                            {children}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default PortalLayout;