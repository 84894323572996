import React, { useState, useEffect } from 'react';
import CrudStudentListFilters from './CrudStudentListFilters';
import { getStudents } from '../../services/scpApi';
import dotsVertical from '../../assets/dots-vertical.svg';
import { studentStatuses } from '../../data/data'; 


const CrudStudentsList = ({setSelectedSingleItemId, setView}) => {

    const [filters, setFilters] = useState({
        school: null,
        yearGroup: null,
        tutorGroup: null,
    });
    const [students, setStudents] = useState([]);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const getStatusName = (status) => {
        const statusObj = studentStatuses.find(item => item.status === status);
        return statusObj ? statusObj.name : status;
    };

    // fetch students
    useEffect(() => {
        (async () => {
            if (!filters.school) {
                setMessage('Please select a school');
                setStudents([]);
                return;
            }

            try {
                // fetch students data
                const data = await getStudents({
                    schoolId: filters.school ? filters.school.id : null,
                    yearGroup: filters.yearGroup ? filters.yearGroup.year : null,
                    tutorGroupId: filters.tutorGroup ? filters.tutorGroup.id : null,
                    includePeriods: true,
                    includeTutorGroup: true,
                });

                // collect the relevant data from the response
                let students = data.map(student => {
                    const latestPeriod = student.pupil_periods[student.pupil_periods.length - 1] || {};

                    return {
                        id: student.id,
                        name: student.name,
                        yearGroup: latestPeriod.year_group || 'N/A',
                        tutorGroup: latestPeriod.tutor_group ? latestPeriod.tutor_group.name : 'N/A',
                        studentStatus: getStatusName(latestPeriod.pupil_status || 'N/A'),
                    };
                });

                // order students by name alphabetically
                students = students.sort((a, b) => a.name.localeCompare(b.name));

                // set the students data
                setStudents(students);
                setMessage(students.length > 0 ? '' : 'No students found');
            } catch (error) {
                console.error('Error fetching students:', error);
                setMessage('Error fetching students');
                setStudents([]);
            }
        })();

    }, [filters.school, filters.yearGroup, filters.tutorGroup, currentPage]);

    const handleEditClick = (student) => {
        setSelectedSingleItemId(student.id);
        setView('single');
    };

    const clearFilters = () => {
        setFilters({
            school: null,
            yearGroup: null,
            tutorGroup: null,
        });
    }

    const columns = [
        { columnLabel: 'Name', dataKey: 'name' },
        { columnLabel: 'Year Group', dataKey: 'yearGroup' },
        { columnLabel: 'Tutor Group', dataKey: 'tutorGroup' },
        { columnLabel: 'Student Status', dataKey: 'studentStatus' }
    ];

    return (
        <div className="">
            <a onClick={() => clearFilters()} className="clear-filters-btn">Clear Filters</a>
            <CrudStudentListFilters filters={filters} setFilters={setFilters} />
            <div className="separator"></div>
            <div className='pagination-count'>Showing {students.length} of {students.length} student{students.length !== 1 ? 's' : ''}</div>
            <div className='crud-list'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.columnLabel}>{column.columnLabel}</th>
                            ))}
                            <th>View / Edit</th>
                        </tr>
                        <tr className="separator-row"></tr>
                    </thead>

                    <tbody>
                        {message ? (
                            <tr>
                                <td colSpan={columns.length + 1}>{message}</td>
                            </tr>
                        ) : (
                            students.length > 0 ? (
                                students.map(student => (
                                    <tr key={student.id}>
                                        {columns.map(column => (
                                            <td key={column.dataKey}>{student[column.dataKey]}</td>
                                        ))}
                                        <td className='edit-dots'>
                                            <button
                                                onClick={() => handleEditClick(student)}
                                                className='edit-dots'>
                                                <img src={dotsVertical} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length + 1}>No students found</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CrudStudentsList;