import React, { useState, useEffect } from 'react';
import CrudTutorGroupListFilters from './CrudTutorGroupListFilters';
import { getTutorGroups, getTutorGroup } from '../../services/scpApi';
import dotsVertical from '../../assets/dots-vertical.svg';

const CrudTutorGroupList = ({setSelectedSingleItemId, setView}) => {

    const [filters, setFilters] = useState({
        school: null
    });
    const [tutorGroups, setTutorGroups] = useState([]);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    // fetch tutor groups
    useEffect(() => {
        (async () => {
            if (!filters.school) {
                setMessage('Please select a school');
                setTutorGroups([]);
                return;
            }

            try {
                // fetch tutor group data
                const data = await getTutorGroups({
                    schoolId: filters.school ? filters.school.id : null
                });

                console.log('data:', data);

                // collect the relevant data from the response
                const tutorGroups = data.map(tutorGroup => {
                    return {
                        id: tutorGroup.id,
                        name: tutorGroup.name,
                        tutorGroupLead: tutorGroup.tutor ? tutorGroup.tutor.name : 'N/A'
                    };
                });

                // set the tutor group data
                setTutorGroups(tutorGroups);
                setMessage(tutorGroups.length > 0 ? '' : 'No tutor groups found');
            } catch (error) {
                console.error('Error fetching tutor groups:', error);
                setMessage('Error fetching tutor groups');
                setTutorGroups([]);
            }
        })();

    }, [filters.school, currentPage]);

    const handleEditClick = (tutorGroup) => {
        
        setSelectedSingleItemId(tutorGroup.id);
        setView('single');
    };

    const clearFilters = () => {
        setFilters({
            school: null,
        });
    }

    const columns = [
        { columnLabel: 'Tutor Group', dataKey: 'name' },
        { columnLabel: 'Tutor Group Lead', dataKey: 'tutorGroupLead' }
    ];

    return (
        <div className="">
            <a onClick={() => clearFilters()} className="clear-filters-btn">Clear Filters</a>
            <CrudTutorGroupListFilters filters={filters} setFilters={setFilters} />
            <div className="separator"></div>
            <div className='pagination-count'>Showing {tutorGroups.length} of {tutorGroups.length} tutor group{tutorGroups.length !== 1 ? 's' : ''}</div>
            <div className='crud-list'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.columnLabel}>{column.columnLabel}</th>
                            ))}
                            <th>View / Edit</th>
                        </tr>
                        <tr className="separator-row"></tr>
                    </thead>

                    <tbody>
                        {message ? (
                            <tr>
                                <td colSpan={columns.length + 1}>{message}</td>
                            </tr>
                        ) : (
                            tutorGroups.length > 0 ? (
                                tutorGroups.map(tutorGroup => (
                                    <tr key={tutorGroup.id}>
                                        {columns.map(column => (
                                            <td key={column.dataKey}>{tutorGroup[column.dataKey]}</td>
                                        ))}
                                        <td className='edit-dots'>
                                            <button
                                                onClick={() => handleEditClick(tutorGroup)}
                                                className='edit-dots'>
                                                <img src={dotsVertical} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length + 1}>No tutor groups found</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default CrudTutorGroupList;