import React from "react";

function LoginLayout({children}) {

    return (
        <div className="login-layout">
            <div className="left-cont">
                <img src={require('../assets/SCP-logo.svg').default} alt="The School Consultancy Programme" />
            </div>
            <div className="right-cont">
                {children}
            </div>
        </div>
    );
}

export default LoginLayout;