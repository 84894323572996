import React, { useState, useEffect } from "react";
import CrudLayout from "../../layouts/CrudLayout";
import CrudTutorList from '../../features/crudTutor/CrudTutorList';
import CrudTutorSingle from '../../features/crudTutor/CrudTutorSingle';
import { getTutor } from '../../services/scpApi';

const ManageTutors = () => {

    const [view, setView] = useState("");
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedTutor, setTutor] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setTutor(null);
    }

    const refreshSelectedTutor = () => {
        if (!selectedSingleItemId) {
            return;
        }
        (async () => {
            try {
                const tutor = await getTutor(selectedSingleItemId);
                setTutor(tutor);
            } catch (error) {
                console.error('Error fetching tutor:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch tutor data
                const data = await getTutor({
                    tutorId: selectedSingleItemId
                });
                setTutor(data.tutor);
            } catch (error) {
                console.error('Error fetching tutor:', error);
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <CrudLayout
            view={view}
            setView={setView}
            selectedSingleItemId={selectedSingleItemId}
            setSelectedSingleItemId={setSelectedSingleItemId}    
        >
            <div className="crud-page">
                <h1>Manage Tutor Group Leads</h1>

                {view === "list" ? (
                    <>
                        <button onClick={handleAddNew} className="add-new">Add New Tutor</button>
                        <CrudTutorList setSelectedSingleItemId={setSelectedSingleItemId} setView={() => setView('single')} />
                    </>
                ) : (
                    <CrudTutorSingle 
                        loading={loading} 
                        setLoading={setLoading} 
                        tutor={selectedTutor}
                        setTutor={setTutor}  
                        setView={() => setView('list')} 
                        refreshSelectedTutor={refreshSelectedTutor} 
                    />
                )}
            </div>
        </CrudLayout>
    );
};

export default ManageTutors;