import React, { useState, useEffect } from "react";
import CrudLayout from "../../layouts/CrudLayout";
import CrudUserList from '../../features/crudUser/CrudUserList';
import CrudUserSingle from '../../features/crudUser/CrudUserSingle';
import { getUser } from '../../services/scpApi';

const ManageUsers = () => {

    const [view, setView] = useState("");
    const [selectedSingleItemId, setSelectedSingleItemId] = useState(null);
    const [selectedUser, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddNew = () => {
        setView("single");
        setSelectedSingleItemId(null);
        setUser(null);
    }

    const refreshSelectedUser = () => {
        if (!selectedSingleItemId) {
            return;
        }
        (async () => {
            try {
                const data = await getUser({
                    userId: selectedSingleItemId
                });
                setUser(data.user);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        })();
    }

    useEffect(() => {
        (async () => {
            if (!selectedSingleItemId) {
                return;
            }
            try {
                // fetch student data
                const data = await getUser({
                    userId: selectedSingleItemId
                });
                setUser(data.user);

            } catch (error) {
                console.error('Error fetching user:', error);
            }
        })();
    }, [selectedSingleItemId]);

    return (
        <CrudLayout
            view={view}
            setView={setView}
            selectedSingleItemId={selectedSingleItemId}
            setSelectedSingleItemId={setSelectedSingleItemId}    
        >
            <div className="crud-page">
                <h1>Manage Users</h1>

                {view === "list" ? (
                    <>
                        <button onClick={handleAddNew} className="add-new">Add New User</button>
                        <CrudUserList setSelectedSingleItemId={setSelectedSingleItemId} setView={() => setView('single')} />
                    </>
                ) : (
                    <CrudUserSingle 
                        loading={loading} 
                        setLoading={setLoading} 
                        user={selectedUser} 
                        setView={() => setView('list')} 
                        refreshSelectedUser={refreshSelectedUser} 
                        setUser={setUser} 
                    />
                )}
            </div>
        </CrudLayout>
    );
}

export default ManageUsers;