import React, {useState, useEffect} from "react";
import LevelDisplayEditable from "../levelDisplayEditable/LevelDisplayEditable";
import LevelDisplay from "../levelDisplay/LevelDisplay";
import {calcAssessmentAverageScore, calcNumbersAreaAverageScore} from "../../includes/utilities";


const BaselineAssessmentForm = ({baselineAssessment, handleBaselineChange}) => {

    const [averages, setAverages] = useState([]);
    const [numbersAverages, setNumbersAverages] = useState([]);

    useEffect(() => {
        updateAverage();
    }, [baselineAssessment])

    // handle level click
    const updateLevelProgress = (areaIndex, topicIndex, level) => {
        const newBaselineAssessment = {...baselineAssessment};
        console.log('updating level', areaIndex, topicIndex, level);
        newBaselineAssessment[areaIndex]['topics'][topicIndex]['level'] = level;
        handleBaselineChange(newBaselineAssessment);
    }

    // update average
    const updateAverage = () => {
        if (!baselineAssessment) {
            return;
        }

        const averageScore = calcAssessmentAverageScore(baselineAssessment);
        const newNumbersAverage = calcNumbersAreaAverageScore(baselineAssessment);
        setAverages([averageScore]);
        setNumbersAverages([newNumbersAverage]);
    }

    return (
        <>
        {baselineAssessment && Object.keys(baselineAssessment).length > 0 &&
            <div className="progress-table">
                <table>
                    <thead>
                        <tr>
                            <th>Area</th>
                            <th>Topic</th>
                            <th>Period</th>
                            <th>Level</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(baselineAssessment).map((area) => 
                            <>
                                {baselineAssessment[area].topics.map((topic, topicIndex) =>
                                    <tr>
                                        <td>{baselineAssessment[area].label}</td>
                                        <td>{topic.label}</td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td>Baseline</td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <LevelDisplayEditable 
                                                            levels={baselineAssessment[area]['topics'][topicIndex]['level']} 
                                                            area={area} 
                                                            topicIndex={topicIndex} 
                                                            updateLevelProgress={(areaIndex, topicIndex, level) => updateLevelProgress(areaIndex, topicIndex, level)}
                                                            startsAt={topic['starts-at'] || 1}
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            {topic.slug == 'ratio-and-proportion' && 
                                                <table style={{position: 'relative'}}>
                                                    <span class="numbers-average-note">Number Areas Average</span>
                                                    {numbersAverages.map((average) =>
                                                        <tr>
                                                            <td>
                                                                <LevelDisplay
                                                                    averageScore={average}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </table>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        <tr>
                            <td>Averages</td>
                            <td></td>
                            <td>
                                <table>
                                    <tr>
                                        <td>Baseline</td>
                                    </tr>
                                </table>
                            </td>
                            <td></td>
                            <td>
                                <table className="averages-table" style={{position: 'relative'}}>
                                    <span class="numbers-average-note">Total Average</span>
                                    {averages.map((average) => 
                                        <tr>
                                            <td>
                                                <LevelDisplay
                                                    averageScore={average}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        </>
    )
}

export default BaselineAssessmentForm;